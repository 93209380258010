import React, { useState, useEffect } from 'react';
import logo from './logo.jpg'
import './App.css';
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
//Star Rating and other modules
import { Rate, Form, Input, Button, message } from 'antd';
import "antd/dist/antd.css";
import axios from 'axios';
import request from 'request';
import { useCookies } from 'react-cookie';
import setting from './setting';


class App extends React.Component {

  getHideIp(ip) {
    let tmp = ip.split(".");
    let hideIp = `${tmp[0]}.${tmp[1]}.${tmp[2]}.***`;
    return hideIp;
  }

  render() {
    const { TextArea } = Input;
    const Demo = () => {
      // const [form] = Form.useForm();

      const info = () => {
        message.info('ขอบคุณค่ะ !');
      };

      const [comments, setComments] = useState([]);
      const [mycomment, setComment] = useState('');
      const [ip, setIP] = useState('');
      const [rating, setRating] = useState(0);
      // eslint-disable-next-line no-unused-vars
      const [cookies, setCookie, removeCookie] = useCookies(['main']);

      function onChange(e) {
        setComment(e.target.value);
      }

      function onRating(value) {
        setRating(value);
      }

      const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        setIP(res.data.IPv4);
      }

      const getComment = async () => {
        axios.post('https://vapi.realtime.co.th/api/use/bampro/collection/comment/find', {
          "query": { "show": true },
          "sort": { "date": -1 },
          "limit": 0,
          "skip": 0,
          "select": ""
        })
          .then(res => {
            setComments(res.data.documents);
            //console.log(res.data.documents);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      function postComment() {
      
            let cIp = cookies.ip;
            let cDate = cookies.date;

            let isOkay = false;
            console.log(`cIp: ${cIp}`);
            console.log(`cDate: ${cDate}`);
            if (cIp && cIp !== ip && cIp !== "") {
              isOkay = true;
              console.log("#1");
            }

            if (cDate) {
              let diff = parseInt((Date.now() - cDate) / (1000 * 60));
              // console.log(`diff: ${diff}`);

              if (diff < 60) {
                isOkay = false;
              }
            } else {
              isOkay = true;
            }

            if (isOkay) {
              axios.post('https://vapi.realtime.co.th/api/use/bampro/collection/comment', [
                {
                  "ip": ip,
                  "comment": mycomment,
                  "rating": rating,
                  "date": new Date().toLocaleString(),
                  "show": true
                }
              ])
                .then(res => {
                  getComment();
                  let btn = document.getElementById("btnReset");
                  if (btn) {
                    btn.click();
                  }
                  info();
                  setCookie("ip", ip, { path: '/' });
                  setCookie("date", Date.now(), { path: '/' });

                  let notifyMsg = `แนะนำติชม -> ${mycomment} จากไอพี ${ip}`;
                  setting.tokens.forEach((token) => {
                    request({
                      method: 'POST',
                      uri: setting.lineNotifyUrl,
                      header: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                      form: {
                        message: notifyMsg, //ข้อความที่จะส่ง
                        token: token
                      }
                    }, (err, httpResponse, body) => {
                      if (err) {
                        console.log(err);
                      } else {
                        console.log(body);
                      }
                    });
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              message.warning("กรุณารอ 1 ชั่วโมง ท่านถึงจะสามารถแนะนำ ติ ชม ได้ใหม่อีกครั้งค่ะ!");
            }
      }

      useEffect(() => {
        getComment();
        getData();
      }, []);

      return (
        <Form
          name="basic"
          layout="vertical"
          onFinish={postComment}
          initialValues={{ remember: false }}>
          <p style={{ textAlign: 'center' }}>ยินดีต้อนรับ สู่ระบบคำแนะนำของ โรงเตี๊ยม<br />
            เชิญท่านๆ ให้คำแนะนำหรือติชมด้านล่างนะคะ</p><br />
          <p><Form.Item
            label="ระดับความชอบ"
            name="rating"
            rules={[{ required: true, message: "กรุณาให้ ดาว ด้วยค่ะ!" }]}>
            <Rate allowHalf defaultValue={0} onChange={onRating} />
          </Form.Item>
          </p>
          <Form.Item
            label="คำแนะนำ ติ ชม"
            name="comment"
            rules={[{ required: true, message: "กรุณาใส่ คำแนะนำ ติ ชม ด้วยค่ะ!" }]}>
            <TextArea
              name={"txtComment"}
              id={"txtComment"}
              rows={4}
              value={mycomment}
              onChange={onChange}
              showCount
              maxLength={800}
            />
          </Form.Item>
          <br/>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              ส่งคำแนะนำ ติ ชม
            </Button>{' '}
            <Button id="btnReset" type="primary" htmlType="reset">
              ล้างข้อความ
            </Button>
          </Form.Item>
          <Form.Item>
            <br /><p>ประวัติคำแนะนำติชม<br /></p>
            {comments.map(x => {
              return (
                <p key={x['_id']}>
                  ไอพี: {this.getHideIp(x['ip'])}&nbsp;
                  <Rate allowHalf defaultValue={x['rating']} />
                  <TextArea
                    value={x['comment']}
                    readOnly={true}
                    bordered={false}
                    style={{ backgroundColor: 'lightgray' }}
                    autoSize>
                  </TextArea>
                  <p style={{ fontSize: 10, color: 'lightgray' }}>{x['date']}</p>
                </p>
              );
            })}
          </Form.Item>
        </Form >
      );
    }
    return (
      <div className="MainDiv" >
        <div className="jumbotron text-center" style={{ height: 280 }}>
          <img src={logo} className="App-logo" alt="logo" />
        </div>

        <div className="container">
          <Demo />
        </div>
      </div>
    );
  };
};

export default App;
